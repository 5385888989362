import { CabButton } from "@CabComponents/CabButton";
import { Box, styled, Typography } from "@mui/material";
import { ReactElement } from "react";
import colors from "../../../colors";
import { CABINET_FEATURES_PAGE_URL, EVENT_TYPE } from "../../../constants";
import { CABINET_LOGO_SECONDARY } from "../../../resourceUrls";
import { trackEventWithExtra } from "../../../utils/appAnalyticsUtils";

const StyledBox = styled(Box, {label: "StyledBox"})({
  border: '2px solid rgba(130, 130, 216, 0.6)',
  backgroundColor: colors.purpleLight,
  borderRadius: 8,
  padding: "24px",
  display: 'flex',
  flexDirection: 'column',
  width: 'fit-content',
  gap: 16
});

const CabinetLogo = styled('img', { label: "CompanyLogo" })(
  {
    width: 40,
    height: "auto"
  }
);

export interface PollResponseCTAProps {
  isAuthenticated: boolean;
  event: EVENT_TYPE.MEETING_BOOKED_SIGNUP | EVENT_TYPE.MEETING_POLL_SIGNUP;
  organizationId: number;
  organizationName: string | null
  userId: number;
  meetingId: number;
}
  
export const PollResponseCTA = ({ 
  isAuthenticated,
  event,
  organizationId,
  organizationName,
  userId,
  meetingId
}: PollResponseCTAProps): ReactElement => {

  const handleClickLearnMore = (eventName: EVENT_TYPE.MEETING_BOOKED_SIGNUP | EVENT_TYPE.MEETING_POLL_SIGNUP) => {
    trackEventWithExtra({
      eventName,
      extra: {
        organizationId: organizationId,
        organizationName: organizationName || "Not Defined",
        userId: userId,
        meetingId: meetingId
      }
    });
    window.open(CABINET_FEATURES_PAGE_URL, '_newtab');
  };

  return (
    <>
      {!isAuthenticated && (
        <StyledBox>
          <CabinetLogo src={CABINET_LOGO_SECONDARY} alt='Cabinet'/>
          <div>
            <Typography variant="h5">Powered by Cabinet</Typography>
            <Typography variant="body1" color={colors.black800} marginTop={1}>
              Find out more about how Cabinet can help you with your scheduling needs.
            </Typography>
          </div>
          <div>
            <CabButton 
              size="large"
              onClick={() => handleClickLearnMore(event)}
            >
              Learn More
            </CabButton>
          </div>
        </StyledBox>
      )}
    </>
  );
};

export default PollResponseCTA;